import loadFirebase from './loadFirebase';
import * as firebase from 'firebase/app';

export default async function devauth() {
  if (
    window.location.hostname === 'betterdays.life' ||
    window.location.hostname === 'localhost'
  ) {
    return;
  }
  if (
    window.location.hostname !== 'roboto.txtだよりで無効にする。テストの都合'
  ) {
    return;
  }

  await loadFirebase;
  firebase.auth().onAuthStateChanged((user) => {
    if (
      !user ||
      !user.email ||
      !user.email.match(/@(actindi.net|sei-zan.net)$/)
    ) {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      firebase.auth().signInWithRedirect(provider);
    }
  });
}
