import * as $ from 'jquery';
/* import './signup'; */
import {
  handleLoadTop,
  modal,
  /* sliderInit, */
  smoothScroll,
  widthCheck,
} from './assets/js/function.js';
import objectFitImages from 'object-fit-images';
import { handleSelectLang } from 'src/utils';
import './assets/js/slick.js';
import './assets/css/slick.min.css';
import './assets/_sass/style.scss';
import './css/_errors.sass';

// 開発環境用の認証
import devauth from './devauth';
devauth();

$(() => {
  smoothScroll();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (objectFitImages as any)('img.u-ojf');

  /* sliderInit(); */

  let winW = window.innerWidth;
  $(window).on('resize', () => {
    if (winW !== window.innerWidth) {
      winW = window.innerWidth;
      widthCheck();
    }
  });

  $('.js-modal').on('click', function () {
    const mode = $(this).attr('href');
    modal(mode);
    return false;
  });

  $(window).on('load', handleLoadTop);

  handleSelectLang();
});
