import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const loadFirebase = new Promise((resolve) => {
  fetch('/__/firebase/init.json')
    .then((response) => {
      return response.json();
    })
    .then((config) => {
      firebase.initializeApp(config);
      resolve(firebase);
    });
});

export default loadFirebase;
