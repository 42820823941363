import MyDispatch from '../../MyDispatch';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

interface StartLoadingAction {
  type: 'START_LOADING';
}

interface StopLoadingAction {
  type: 'STOP_LOADING';
}

export type LoadingAction = StartLoadingAction | StopLoadingAction;

export function startLoading() {
  return { type: START_LOADING };
}

export function stopLoading() {
  return { type: STOP_LOADING };
}

export async function withLoading<T>(dispatch: MyDispatch, fun: () => T) {
  dispatch(startLoading());
  try {
    return await fun();
  } finally {
    dispatch(stopLoading());
  }
}
