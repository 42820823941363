import MyThunkAction from 'src/MyThunkAction';
import { withLoading } from './common/actions/loading';
import MyDispatch from './MyDispatch';

export function normalizeBoolean(value: string): boolean {
  return value === 'true';
}

export function normalizeInteger(value: string): number {
  return parseInt(value, 10);
}

export function scrollTo(id: string): void {
  setTimeout(() => {
    const element = document.getElementById(id);
    return (
      element && element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    );
  }, 0);
}

export function trackingId(days: number): string {
  return `${days}`;
}

export function truncate(text: string, length: number): string {
  if (!text) {
    return '';
  }
  if (text.length < length) {
    return text;
  }
  return `${text.substr(0, length - 3)}...`;
}

export function withDispatch<T>(f: (dispatch: MyDispatch) => T): MyThunkAction {
  return async (dispatch: MyDispatch) => {
    return await withLoading(dispatch, async () => {
      await f(dispatch);
    });
  };
}

export interface NamedBlob {
  name: string;
  blob: Blob;
}

export function detectLang(): string {
  const host = location.host;
  switch (host.split(/[.:-]/)[0]) {
    case 'localhost':
      switch (location.port) {
        case '9001':
          return 'en';
        default:
          return 'ja';
      }
    case 'en':
      return 'en';
    default:
      return 'ja';
  }
}

export function gotoEn() {
  const host = location.host;
  if (host.match('betterdays.life')) {
    location.href = location.href.replace(
      'https://betterdays.life',
      'https://en.betterdays.life'
    );
  } else if (host.match('firebaseapp.com')) {
    location.href = location.href.replace('https://', 'https://en-');
  } else {
    // localhost
    location.href = location.href.replace(':9000', ':9001');
  }
}

export function handleSelectLang() {
  const currentLang = detectLang();
  const ja = document.getElementById('select-lang-ja') as HTMLAnchorElement;
  if (ja) {
    ja.addEventListener('click', (event: Event) => {
      event.preventDefault();
      const host = location.host;
      if (host.match('betterdays.life')) {
        location.href = location.href.replace(
          'https://en.betterdays.life',
          'https://betterdays.life'
        );
      } else if (host.match('firebaseapp.com')) {
        location.href = location.href.replace('https://en-', 'https://');
      } else {
        // localhost
        location.href = location.href.replace(':9001', ':9000');
      }
    });
    if (currentLang === 'ja') {
      ja.setAttribute('class', 'current');
    }
  }

  const en = document.getElementById('select-lang-en') as HTMLAnchorElement;
  if (en) {
    en.addEventListener('click', (event: Event) => {
      event.preventDefault();
      gotoEn();
    });
    if (currentLang === 'en') {
      en.setAttribute('class', 'current');
    }
  }
}
