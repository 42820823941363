import * as $ from 'jquery';
import gsap from 'gsap';

var BREAK_POINT_TB = 768;
// var BREAK_POINT_SP = 640;
var timer = 0;
// var winW;

export function sliderInit(){
  $('.l-main__slide').on('init',function(event, slick){
    $('.l-main__slide li figure').hide();
    $('.l-main__slide li figure').each(function(i) {
      $(this).delay(1000 * i).fadeIn(1000).addClass('anime');
    });
  });
  $('.l-main__slide').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 10000,
    slidesToShow: 3,
    responsive: [{
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
      }
    },{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      }
    }
                ]
  });
}

// 画面サイズのチェック
export function widthCheck() {
  if (timer > 0) {
    clearTimeout(timer);
  }
  timer = setTimeout(function () {
    $('.l-main__slide').slick('unslick');
    sliderInit();
  }, 200);
}

export function smoothScroll(){
  var trigger = $('.js-scroll');
  var pos;

  trigger.on('click',function(){
    pos = $($(this).attr('href')).offset().top;
    $('html , body').animate({
      scrollTop: pos
    },400);
    return false;
  });
}

export function userMenu(){
  var usermenuBth = $('.js-usermenuBtn');
  var usermenu = $('.js-usermenu');
  usermenuBth.on('click',function(){
    usermenu.fadeToggle();
  });
  $(document).on('click touchend', function(event) {
    if (!$(event.target).closest($('.l-header__user')).length) {
      usermenu.fadeOut();
    }
  });
}

//タブの枠幅設定
export function setNavWidth(){
  if(window.innerWidth <= BREAK_POINT_TB){
    var num = $('.l-tab ul > li').length;
    var w = $('.l-tab ul > li').width();
    $('.l-tab ul').css('width',w * num +'px');
  }
}

export function modal(mode){
  var modal = $('.c-modal');
  var close = $('.js-modalClose');
  $('html,body').addClass('u-noScr');
  noScroll();
  $(mode).css('display','block');
  modal.addClass('show');
  if(mode == '#record'){
    celebrate();
  }else if(mode == '#share'){
    close.addClass('nega');
  }else if(mode == '#case00' || mode == '#case01'){
    var num = mode.replace( /#case0/g , "" )
    $('.c-usecase__slide_0'+num).slick('setPosition');
  }

  close.on('click',function(){
    modal.removeClass('show');
    $(mode).css('display','none');
    close.removeClass('nega');
    $('html,body').removeClass('u-noScr');
    return false;
  });
}

function noScroll(){
  var state = false;
  var scrollpos;
  
  if(state == false) {
    scrollpos = $(window).scrollTop();
    $('body').addClass('fixed').css({'top': -scrollpos});
    state = true;
  } else {
    $('body').removeClass('fixed').css({'top': 0});
    window.scrollTo( 0 , scrollpos );
    state = false;
  }
}

//animation
export function celebrate(){

  setText({ selector : '.message' });

  function setText( name ){
    if( name.selector ) {
      var elements = document.querySelectorAll( name.selector );
      var words = [];

      $.each( elements , function( i , val ){
        var sentence = this.textContent;
        words[i] = sentence.split(' ');

        this.innerHTML = '';

        $.each( words[i] , function( j , val ){
          words[i][j] = val.split('');
          var word = document.createElement('span');
          word.className = 'word';
          elements[i].appendChild(word);

          $.each( words[i][j] , function( k , val ){
            var string = document.createElement('span');
            string.className = 'string';
            string.innerHTML = words[i][j][k];
            word.appendChild(string);
          });
        });
      });
    }
  }

  function anime1() {
    gsap.to(".string", {
      duration: 0.3,
      y: -20 ,
      opacity: 0,
      stagger: {
        each: 0.1,
        repeat: 1,
        yoyo: true
      }
    });
  }

  anime1();
}

//編集-accordion
function accordion(){
  $(document).on('click','.js-accordion > dt',function(){
    if(!$(this).hasClass('open')){
      $('.js-accordion dt').removeClass('open').closest('dd').slideUp(0);
      $(this).addClass('open').next('dd').slideDown(400);
    }else{
      $(this).removeClass('open').next('dd').slideUp(400);
    }
  });
  $(document).on('click','.js-removeList',function(){
    if(!confirm('本当に削除しますか？')){
      /* キャンセルの時の処理 */
      return false;
    }else{
      /*　OKの時の処理 */
      $(this).parents('.js-accordion').remove();
    }
  });
}

export function floatInfo(){
    var info = $('.js-information');

    $(window).scroll(function(){
        var targetPos = getftPos() - window.innerHeight;
        if($(window).scrollTop() > targetPos){
            info.addClass('static');
        } else{
            info.removeClass('static');
        }
    });
}

function getftPos(){
    return($('footer').offset().top);
}

export function handleLoadTop() {
  $('.c-usecase__modal').each(function(index, element) {
    $('.c-usecase__slide_0' + index).slick({
      arrows: false,
      autoplay: false,
      cssEase: 'ease-out',
      infinite: false,
      speed: 400,
      slidesToShow: 1
    });
    $('.c-usecase__slide_0' + index).on('init',function(event, slick, currentSlide, nextSlide) {
      $('#case0' + index +' .js-slider-prev').addClass('deactive');
    });
    $('.c-usecase__slide_0' + index).on('beforeChange',function(event, slick, currentSlide, nextSlide) {
      if(nextSlide == 0){
        $('#case0' + index +' .js-slider-prev').addClass('deactive');
      }else if(nextSlide == 2){
        $('#case0' + index +' .js-slider-next').addClass('deactive');
      }else{
        $('#case0' + index +' .js-slider-prev').removeClass('deactive');
        $('#case0' + index +' .js-slider-next').removeClass('deactive');
      }
    });
    $('#case0' + index + ' .js-slider-prev').on('click',function(){
      $('.c-usecase__slide_0' + index).slick('slickPrev');
    });
    $('#case0' + index + ' .js-slider-next').on('click',function(){
      $('.c-usecase__slide_0' + index).slick('slickNext');
    });
  });
}
